<template>
  <el-dialog
    title="订单发货"
    :visible.sync="twoModelShow"
    width="1000px"
    :before-close="handleClose"
  >
    <div class="deliver_order_dialog">
      <div
        v-if="orderData"
        class="header"
      >
        <div class="order_number">
          <span style="margin-right: 40px">订货单号: {{ orderData.supplyNo }}</span>
        </div>
        <el-button
          v-if="type === 'add'"
          type="primary"
          size="small"
          @click="operationWaybillOrder('add')"
        >
          添加运单
        </el-button>
      </div>
      <el-divider />
      <div
        v-for="(item, i) in dataList"
        :key="i"
        class="waybill"
      >
        <el-row
          :gutter="20"
          type="flex"
          :align="'top'"
        >
          <el-col :span="2">
            运单{{ i + 1 }}:
          </el-col>
          <el-col :span="22">
            <div class="waybill_header">
              <div class="waybill_header_box">
                <p>
                  <span style="color: red">*</span>
                  运单号：
                </p>
                <el-input
                  v-if="type === 'add' && orderData.shippingType === 1"
                  v-model.trim="item.logisticNo"
                  placeholder="请输入运单号"
                  size="small"
                  :disabled="type === 'edit'"
                  @input="debounceGetLogisticFn(item)"
                />
                <span v-if="type === 'add' && orderData.shippingType === 2">无</span>
                <span v-if="type === 'edit'">{{ item.logisticNo }}</span>
              </div>
              <div class="waybill_header_box">
                <p>
                  <span style="color: red">*</span>
                  物流公司：
                </p>
                <el-select
                  v-if="type === 'add'"
                  v-model="item.logisticName"
                  placeholder="请选择物流公司"
                  size="small"
                  :disabled="type === 'edit'"
                >
                  <el-option
                    v-for="(value, index) in logisticsCompanys"
                    :key="index"
                    :label="value.logisticName"
                    :value="value.logisticName"
                  />
                </el-select>
                <span v-if="type === 'edit'">{{ item.logisticName }}</span>
              </div>
              <div class="waybill_header_box">
                <p>备注</p>
                <el-input
                  v-model="item.memo"
                  type="textarea"
                  :rows="4"
                  placeholder="备注"
                  size="small"
                  maxlength="350"
                  show-word-limit
                />
              </div>
              <div style="margin-left: 70px;">
                <UpImageNew
                  :image-list.sync="item.memoImg"
                  type="string"
                  :num="5"
                />
                <div>
                  建议：800px*800px，2M以内；图片可拖动排序，限5张。
                </div>
              </div>
              <el-link
                v-if="type === 'add' && dataList.length > 1"
                type="danger"
                :underline="false"
              >
                <i
                  class="el-icon-delete"
                  @click="operationWaybillOrder('delete', i)"
                ></i>
              </el-link>
            </div>

            <div class="waybill_goods_select">
              <el-checkbox
                v-model="item.relateGoods"
                :true-label="1"
                :false-label="0"
                @change="(e)=>{changeRelateGoods(e,item)}"
              >
                关联商品
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="整单发货时，无需关联商品；多运单发货时，必须关联商品"
                  placement="right"
                >
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-checkbox>
            </div>

            <div v-if="item.relateGoods">
              <div
                v-for="(value,itemIndex) in item.packageRelateGoodsDetailList"
                v-show="value.isDelete !== 1"
                :key="itemIndex"
                class="waybill_header waybill_goods_box flex"
              >
                <div class="waybill_header_box">
                  <p><span style="color: red">*</span>商品</p>
                  <el-select
                    v-model="value.subOrderId"
                    popper-class="waybill_goods_select"
                    placeholder="请选择商品"
                    size="small"
                    @change="changeGoods(value, item)"
                  >
                    <el-option
                      v-for="(goodsitem, index) in goodsList"
                      :key="index"
                      :label="goodsitem.goodsName"
                      :value="goodsitem.subOrderId"
                      :disabled="disabledGoodsFn(goodsitem, item)"
                    >
                      <div>
                        <span style="float: left; font-size: 14px;">
                          {{ goodsitem.goodsName }}（{{ goodsitem.skuName }}）
                        </span>
                        <span
                          style="float: left;display: block;
                        position:absolute;margin-top: 20px;font-size: 12px"
                        >
                          下单 {{ goodsitem.num }}
                          <!-- 已发 {{ goodsitem.shipmentCount }} / 下单 {{ goodsitem.num }} -->
                        </span>
                        <!-- <div>已发 {{ goodsitem.sendNum }} / 下单 {{ goodsitem.allNum }}</div> -->
                      </div>
                    </el-option>
                  </el-select>
                </div>
                <div class="waybill_header_box">
                  <p><span style="color: red">*</span>数量</p>
                  <div>
                    <el-input-number
                      v-model="value.shipmentCount"
                      controls-position="right"
                      :min="0"
                      :max="countGoodsMaxNum(value.subOrderId)"
                      size="small"
                      @change="changeGoodsNum(value)"
                    />
                    <div
                      v-if="value.shipmentCount === 0"
                      style="color: red; font-size: 10px; line-height: 18px"
                    >
                      请输入商品数量
                    </div>
                  </div>
                </div>
                <el-link
                  v-if="item.packageRelateGoodsDetailList
                    && item.packageRelateGoodsDetailList.length > 1"
                  :underline="false"
                  type="danger"
                >
                  <i
                    class="el-icon-delete"
                    @click="deleteGoods(itemIndex, item)"
                  ></i>
                </el-link>
              </div>

              <el-button
                style="margin-top: 10px"
                type="primary"
                size="small"
                @click="addGoods(item)"
              >
                添加商品
              </el-button>
            </div>
          </el-col>
        </el-row>
        <el-divider />
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        :loading="btnLoading"
        @click="submit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { defineComponent } from 'vue';
import UpImageNew from '@/components/common/upImageNew/index';
import { debounce } from '@/components/common/common';

export default defineComponent({
  name: '',
  components: { UpImageNew },
  model: {
    prop: 'twoModelShow',
    event: 'changeTwoModelShow',
  },
  props: {
    twoModelShow: Boolean,
    orderData: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'edit',
    },
  },
  data() {
    return {
      dialogVisible: false,
      dataList: [],
      logisticsCompanys: [],
      goodsList: [],
      btnLoading: false,
      debounceGetLogisticFn: undefined,
    };
  },
  computed: {},
  watch: {
    twoModelShow: {
      async handler(val) {
        if (val) {
          console.log('第二层，弹窗打开==================');
          // 获取快递列表
          if (this.type === 'add') {
            this.getLogisticList();
          }
          // 获取订单详情
          if (this.orderData) {
            if (this.type === 'edit') {
              let row = await this.getDataPromise(
                this.orderData.orderId,
                this.$api.deliver_center.download_import_detail,
              );
              this.dataList = row;
            } else if (this.type === 'add') {
              // 通过运单号查询快递公司
              this.operationWaybillOrder('add');
            }
            // 获取商品列表
            // let goodsData = await this.getDataPromise(this.orderData.orderId,
            // this.$api.deliver_center.download_goodsList);
            this.goodsList = this.orderData.supplyOrderGoodsVOList.map((item) => {
              return {
                ...item,
                subOrderId: item.supplyOrderGoodsId ? item.supplyOrderGoodsId : item.id,
                // 已发货数量（供应链默认全部发货）、通过canDeliver 是否可发货判断
                shipmentCount: item.canDeliver ? 0 : item.purchaseCount,
                num: item.purchaseCount, // 订货数量
              };
            });
          }
        }
      },
    },
  },
  created() { },
  mounted() {
    this.debounceGetLogisticFn = debounce(this.getLogisticData);
  },
  methods: {
    // 获取快递数据
    getLogisticData(row) {
      (async () => {
        const res = await this.$axios.get(this.$api.deliver_center.download_logisticByNo, {
          params: {
            logisticNo: row.logisticNo,
          },
        });
        if (res.code !== 0 || !res.data) {
          row.logisticName = '';
          return;
        }
        row.logisticName = res.data.logisticName;
      })();
    },
    handleClose() {
      this.$emit('changeTwoModelShow', false);
      this.dataList = [];
    },
    // 获取快递
    getLogisticList() {
      this.$axios.get(this.$api.commonNew.getLogisticList, {
        params: {
          type: this.orderData.shippingType,
        },
      }).then((res) => {
        this.logisticsCompanys = res.data || [];
      });
    },
    submit() {
      let newArray = [];
      console.log(this.dataList, '处理前数据');
      for (let value of this.dataList) {
        if (this.type === 'add' && this.orderData.shippingType === 1 && !value.logisticNo) {
          this.$message.error('请输入运单号');
          return;
        }

        if (this.type === 'add' && !value.logisticName) {
          this.$message.error('请选择物流公司');
          return;
        }

        let newobj = {
          ...value,
          packageRelateGoodsDetailList: [],
        };

        if (value.relateGoods) {
          if (!value.packageRelateGoodsDetailList || !value.packageRelateGoodsDetailList.length) {
            this.$message.error('请添加商品');
            return;
          }
          for (let goods of value.packageRelateGoodsDetailList) {
            if (!goods.subOrderId) {
              this.$message.error('请选择商品');
              return;
            }
            if (!goods.shipmentCount) {
              this.$message.error('请输入商品数量');
              return;
            }
            let find = this.goodsList.find((find) => find.subOrderId === goods.subOrderId);
            if (goods.shipmentCount > (find.num - find.shipmentCount)) {
              this.$message.error(`商品（${find.goodsName}）数量（${goods.shipmentCount}）不能大于剩余可发货数量（${find.num - find.shipmentCount}）`);
              return;
            }
            newobj.packageRelateGoodsDetailList.push({
              ...goods,
              subOrderId: find.subOrderId,
            });
          }
        }
        newArray.push(newobj);
      }

      let api; let params; let
        message;
      if (this.type === 'edit') {
        api = this.$api.deliver_center.download_import_edit;
        message = '是否确认保存？';
        params = {
          importDeliverEditList: newArray,
        };
      } else if (this.type === 'add') {
        api = this.$api.supplierOrderOrder.deliver;
        message = '是否确认发货？';
        params = {
          supplyNo: this.orderData.supplyNo,
          supplyOrderDeliverList: newArray.map((item) => {
            return {
              logisticName: item.logisticName,
              logisticNo: item.logisticNo,
              memo: item.memo,
              memoImg: item.memoImg,
              supplyOrderGoodsDeliverList: item.packageRelateGoodsDetailList.map((goods) => {
                return {
                  supplyOrderGoodsId: goods.subOrderId,
                  shipmentCount: goods.shipmentCount,
                };
              }),
            };
          }),
        };
        console.log(params, '提交数据');
      }
      // 判断是否有商品没有发完 =============================
      let obj = {};
      let isNotGoods;
      for (let item of newArray) {
        // 判断是否有未关联商品的，不用提示
        isNotGoods = item.relateGoods;
        for (let value of item.packageRelateGoodsDetailList) {
          if (obj[value.subOrderId]) {
            obj[value.subOrderId] += value.shipmentCount;
          } else {
            obj[value.subOrderId] = value.shipmentCount;
          }
        }
      }
      for (let item of this.goodsList) {
        let num = obj[item.subOrderId] || 0;
        if (item.num > (num + item.shipmentCount) && isNotGoods) {
          message = '存在商品还未发完，是否确认发货？';
          break;
        }
      }
      // =============================
      console.log(newArray, '提交数据：');

      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.btnLoading = true;
        this.$axios.post(api, params).then((res) => {
          this.btnLoading = false;
          if (res.code === 0) {
            this.$message.success('操作成功！');
            this.handleClose();
            this.$emit('success');
          } else {
            this.$alert(res.msg, '操作失败', {
              confirmButtonText: '确定',
              type: 'error',
              callback: (action) => {
                console.log(action);
              },
            });
          }
        });
      }).catch(() => {
      });
    },
    // 添加商品
    addGoods(item) {
      item.packageRelateGoodsDetailList.push({
        subOrderId: '',
        shipmentCount: 0,
      });
    },
    // 选择商品
    changeGoods(value) {
      // value.shipmentCount = '';
      let find = this.goodsList.find((find) => Number(find.subOrderId)
      === Number(value.subOrderId));
      let max = find.num - find.shipmentCount;
      if (value.shipmentCount > max) {
        // set方法
        // value.shipmentCount = max;
        this.$set(value, 'shipmentCount', max);
      }
    },
    // 计算数量最大值
    countGoodsMaxNum(subOrderId) {
      if (this.goodsList.length === 0) return 1;
      if (!subOrderId) return 1;
      let find = this.goodsList.find((find) => Number(find.subOrderId) === Number(subOrderId));
      if (!find) return 1;
      return find.num - find.shipmentCount;
    },
    // 修改商品数量
    changeGoodsNum(item) {
      console.log(item);
      console.log(this.goodsList);
      // this.goodsList
      // this.dataList
      let find = this.goodsList.find((find) => Number(find.subOrderId) === Number(item.subOrderId));
      let num = 0;
      this.dataList.forEach((row) => {
        row.packageRelateGoodsDetailList.forEach((value) => {
          if (value.subOrderId === item.subOrderId) {
            num += value.shipmentCount;
          }
        });
      });
      let max = find.num - find.shipmentCount - num + item.shipmentCount;
      console.log(item.shipmentCount, max, num);
      if (item.shipmentCount > max) {
        this.$message.error('商品总数量不能大于下单数');
        setTimeout(() => {
          // set方法
          // item.shipmentCount = max;
          this.$set(item, 'shipmentCount', max);
        }, 50);
      }
    },
    deleteGoods(i, item) {
      console.log(i, item);
      this.$confirm('是否确认删除关联商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        item.packageRelateGoodsDetailList.splice(i, 1);
      }).catch(() => {
      });
    },
    // 添加运单
    operationWaybillOrder(type, index) {
      if (type === 'add') {
        this.dataList.push({
          ...this.orderData,
          logisticNo: '',
          logisticName: '',
          packageRelateGoodsDetailList: [{
            subOrderId: '',
            shipmentCount: 0,
          }],
        });
      } else if (type === 'delete') {
        this.$confirm('是否确认删除运单数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.dataList.splice(index, 1);
        }).catch(() => {
        });
      }
    },
    getDataPromise(orderId, api) {
      return new Promise((resolve) => {
        this.$axios.get(api, {
          params: {
            orderId,
          },
        }).then((res) => {
          if (res.code === 0) {
            resolve(res.data);
          }
        });
      });
    },
    // 商品选择框禁用逻辑
    disabledGoodsFn(goodsitem, item) {
      // 售后状态 1、待商家处理 2、退款中 3、待用户处理 4、平台处理中 5、退款成功 6、退款失败 0、可申请售后
      // 判断指定状态  供应链中统一使用canDeliver字段，判断是否可发货 废弃售后状态判断
      // let refundStatusReturn = [1, 2, 4, 5].indexOf(goodsitem.refundStatus) !== -1;
      // 判断是否选中
      let changeReturn = !!item.packageRelateGoodsDetailList.find((find) => (find.subOrderId
      === goodsitem.subOrderId));
      // 判断数量是否正确
      let numEnough = goodsitem.shipmentCount >= goodsitem.num;
      return changeReturn || numEnough;
    },
    changeRelateGoods(e, item) {
      console.log(e, item);
      if (e === 1 && item
      && item.packageRelateGoodsDetailList
      && item.packageRelateGoodsDetailList.length === 0) {
        item.packageRelateGoodsDetailList.push({
          subOrderId: '',
          shipmentCount: 0,
        });
      }
    },
  },
});
</script>

<style scoped lang="scss">
.deliver_order_dialog {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .order_number {
    font-size: 16px;
    font-weight: bold;
  }
}
.waybill_header {
  // display: flex;
  // justify-content: space-between;
  .waybill_header_box {
    margin-bottom: 10px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin-right: 15px;
    width: 30%;
    > p {
      margin-right: 5px;
      min-width: 60px;
      text-align: center;
    }
  }
}
.waybill_goods_box {
  margin-top: 10px;
}

// 下下拉框样式
.waybill_goods_select {
  margin-top: 20px;
  .el-select-dropdown__item {
    height: 55px;
  }
}
</style>
