var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "订单发货",
        visible: _vm.twoModelShow,
        width: "1000px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.twoModelShow = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "deliver_order_dialog" },
        [
          _vm.orderData
            ? _c(
                "div",
                { staticClass: "header" },
                [
                  _c("div", { staticClass: "order_number" }, [
                    _c("span", { staticStyle: { "margin-right": "40px" } }, [
                      _vm._v("订货单号: " + _vm._s(_vm.orderData.supplyNo)),
                    ]),
                  ]),
                  _vm.type === "add"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.operationWaybillOrder("add")
                            },
                          },
                        },
                        [_vm._v(" 添加运单 ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("el-divider"),
          _vm._l(_vm.dataList, function (item, i) {
            return _c(
              "div",
              { key: i, staticClass: "waybill" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20, type: "flex", align: "top" } },
                  [
                    _c("el-col", { attrs: { span: 2 } }, [
                      _vm._v(" 运单" + _vm._s(i + 1) + ": "),
                    ]),
                    _c("el-col", { attrs: { span: 22 } }, [
                      _c(
                        "div",
                        { staticClass: "waybill_header" },
                        [
                          _c(
                            "div",
                            { staticClass: "waybill_header_box" },
                            [
                              _c("p", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" 运单号： "),
                              ]),
                              _vm.type === "add" &&
                              _vm.orderData.shippingType === 1
                                ? _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入运单号",
                                      size: "small",
                                      disabled: _vm.type === "edit",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.debounceGetLogisticFn(item)
                                      },
                                    },
                                    model: {
                                      value: item.logisticNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item,
                                          "logisticNo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "item.logisticNo",
                                    },
                                  })
                                : _vm._e(),
                              _vm.type === "add" &&
                              _vm.orderData.shippingType === 2
                                ? _c("span", [_vm._v("无")])
                                : _vm._e(),
                              _vm.type === "edit"
                                ? _c("span", [_vm._v(_vm._s(item.logisticNo))])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "waybill_header_box" },
                            [
                              _c("p", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" 物流公司： "),
                              ]),
                              _vm.type === "add"
                                ? _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择物流公司",
                                        size: "small",
                                        disabled: _vm.type === "edit",
                                      },
                                      model: {
                                        value: item.logisticName,
                                        callback: function ($$v) {
                                          _vm.$set(item, "logisticName", $$v)
                                        },
                                        expression: "item.logisticName",
                                      },
                                    },
                                    _vm._l(
                                      _vm.logisticsCompanys,
                                      function (value, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: value.logisticName,
                                            value: value.logisticName,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                              _vm.type === "edit"
                                ? _c("span", [
                                    _vm._v(_vm._s(item.logisticName)),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "waybill_header_box" },
                            [
                              _c("p", [_vm._v("备注")]),
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 4,
                                  placeholder: "备注",
                                  size: "small",
                                  maxlength: "350",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: item.memo,
                                  callback: function ($$v) {
                                    _vm.$set(item, "memo", $$v)
                                  },
                                  expression: "item.memo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "70px" } },
                            [
                              _c("UpImageNew", {
                                attrs: {
                                  "image-list": item.memoImg,
                                  type: "string",
                                  num: 5,
                                },
                                on: {
                                  "update:imageList": function ($event) {
                                    return _vm.$set(item, "memoImg", $event)
                                  },
                                  "update:image-list": function ($event) {
                                    return _vm.$set(item, "memoImg", $event)
                                  },
                                },
                              }),
                              _c("div", [
                                _vm._v(
                                  " 建议：800px*800px，2M以内；图片可拖动排序，限5张。 "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm.type === "add" && _vm.dataList.length > 1
                            ? _c(
                                "el-link",
                                { attrs: { type: "danger", underline: false } },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-delete",
                                    on: {
                                      click: function ($event) {
                                        return _vm.operationWaybillOrder(
                                          "delete",
                                          i
                                        )
                                      },
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "waybill_goods_select" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 1, "false-label": 0 },
                              on: {
                                change: (e) => {
                                  _vm.changeRelateGoods(e, item)
                                },
                              },
                              model: {
                                value: item.relateGoods,
                                callback: function ($$v) {
                                  _vm.$set(item, "relateGoods", $$v)
                                },
                                expression: "item.relateGoods",
                              },
                            },
                            [
                              _vm._v(" 关联商品 "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "整单发货时，无需关联商品；多运单发货时，必须关联商品",
                                    placement: "right",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-warning-outline",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      item.relateGoods
                        ? _c(
                            "div",
                            [
                              _vm._l(
                                item.packageRelateGoodsDetailList,
                                function (value, itemIndex) {
                                  return _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: value.isDelete !== 1,
                                          expression: "value.isDelete !== 1",
                                        },
                                      ],
                                      key: itemIndex,
                                      staticClass:
                                        "waybill_header waybill_goods_box flex",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "waybill_header_box" },
                                        [
                                          _c("p", [
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("商品"),
                                          ]),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                "popper-class":
                                                  "waybill_goods_select",
                                                placeholder: "请选择商品",
                                                size: "small",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeGoods(
                                                    value,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: value.subOrderId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    value,
                                                    "subOrderId",
                                                    $$v
                                                  )
                                                },
                                                expression: "value.subOrderId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.goodsList,
                                              function (goodsitem, index) {
                                                return _c(
                                                  "el-option",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      label:
                                                        goodsitem.goodsName,
                                                      value:
                                                        goodsitem.subOrderId,
                                                      disabled:
                                                        _vm.disabledGoodsFn(
                                                          goodsitem,
                                                          item
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            float: "left",
                                                            "font-size": "14px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                goodsitem.goodsName
                                                              ) +
                                                              "（" +
                                                              _vm._s(
                                                                goodsitem.skuName
                                                              ) +
                                                              "） "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            float: "left",
                                                            display: "block",
                                                            position:
                                                              "absolute",
                                                            "margin-top":
                                                              "20px",
                                                            "font-size": "12px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " 下单 " +
                                                              _vm._s(
                                                                goodsitem.num
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "waybill_header_box" },
                                        [
                                          _c("p", [
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("数量"),
                                          ]),
                                          _c(
                                            "div",
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  "controls-position": "right",
                                                  min: 0,
                                                  max: _vm.countGoodsMaxNum(
                                                    value.subOrderId
                                                  ),
                                                  size: "small",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.changeGoodsNum(
                                                      value
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: value.shipmentCount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      value,
                                                      "shipmentCount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "value.shipmentCount",
                                                },
                                              }),
                                              value.shipmentCount === 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                        "font-size": "10px",
                                                        "line-height": "18px",
                                                      },
                                                    },
                                                    [_vm._v(" 请输入商品数量 ")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      item.packageRelateGoodsDetailList &&
                                      item.packageRelateGoodsDetailList.length >
                                        1
                                        ? _c(
                                            "el-link",
                                            {
                                              attrs: {
                                                underline: false,
                                                type: "danger",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteGoods(
                                                      itemIndex,
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addGoods(item)
                                    },
                                  },
                                },
                                [_vm._v(" 添加商品 ")]
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c("el-divider"),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                loading: _vm.btnLoading,
              },
              on: { click: _vm.submit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }